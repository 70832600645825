<template>
  <div
    class="box"
    v-loading="loading">
    <!-- <ScaleBox
      :width="5760"
      :height="1080"
      bgc="transparent"
      :delay="100"> -->
    <Dialog
      ref="dialog"
      :config="config"
      :width="dialogParam.width"
      :title="dialogParam.title"
      :type="dialogType"
      :dialogVisible="dialogVisible"
      @dialogClose="dialogVisible = false"
      :info="dialogParam.info"></Dialog>
    <div
      class="container"
      :style="{ width: boxWidth, height: boxHeight, minWidth: '570rem' }">
      <div class="header">
        <div class="time">
          {{ dateYear }}&nbsp;&nbsp;{{ dateWeek }} &nbsp;
          <el-divider direction="vertical"></el-divider>
          <span class="now-time">&nbsp;&nbsp;{{ dateDay }}</span>
        </div>
        <div class="title">
          <img src="../../assets/statisitics/Mask group@2x.png" />
          成都市武侯区教育系统健康监测可视化平台
        </div>
        <div class="weather">
          <!-- {{weatcherData.wea_img}} -->
          <img src="../../assets/statisitics/Group 1082@2x.png" />
          &nbsp;&nbsp;{{ weatcherData.tem }}30℃&nbsp;&nbsp;
          <el-divider
            direction="vertical"
            style="height: 1rem"></el-divider>
          &nbsp;&nbsp;
          <img src="../../assets/statisitics/Group 43@2x.png" />
          &nbsp;&nbsp;欢迎使用
        </div>
      </div>
      <div class="content">
        <div
          class="col-box"
          style="height: 50%">
          <div
            class="item-box"
            v-loading="baseLoading">
            <Title
              title="基本情况"
              type="1"></Title>
            <div class="chart-layout">
              <div class="table">
                <el-table
                  :data="baseInfo"
                  :header-cell-style="{
                    background: '#ebf2ff',
                    fontSize: '2.8rem',
                    fontWeight: '100',
                    height: '8rem',
                  }"
                  @row-click="baseInfoClick">
                  <el-table-column
                    prop="period"
                    width="133"></el-table-column>
                  <el-table-column
                    prop="schoolNumber"
                    label="学校"
                    width="104"></el-table-column>
                  <el-table-column
                    prop="teacherNumber"
                    label="教师"
                    width="104"></el-table-column>
                  <el-table-column
                    prop="studentNumber"
                    label="学生"></el-table-column>
                </el-table>
              </div>
              <div class="chart">
                <div
                  ref="jbqk"
                  class="echarts"></div>
              </div>
            </div>
          </div>
          <!-- <div class="item-box">
            <Title title="晨午检完成趋势" type="7"></Title>
            <div class="echarts">
              <div class="list" v-for="(i, index) in this.normalT" :key="index + 22">
                <div>{{ i.illness_name }}</div>
                <div>
                  <span style="color: #3870ff">{{ i.nums }}</span>
                  <span style="font-size: 1rem">人</span>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div
          class="col-box"
          style="height: 50%">
          <div
            class="item-box"
            v-loading="cwjLoading">
            <Title
              :title="nowDate + '晨午检'"
              type="2"></Title>
            <div class="chart-layout">
              <div class="table">
                <el-table
                  :data="checkNums"
                  :header-cell-style="{
                    background: '#ebf2ff',
                    fontSize: '2.8rem',
                    fontWeight: '100',
                    height: '8rem',
                  }"
                  @row-click="chenwuClick">
                  <el-table-column
                    prop="name"
                    width="133"></el-table-column>
                  <el-table-column
                    prop="chenNums"
                    label="晨检"
                    width="104"></el-table-column>
                  <el-table-column
                    prop="wuNums"
                    label="午检"
                    width="104"></el-table-column>
                  <el-table-column
                    prop="wanNums"
                    label="晚检"></el-table-column>
                </el-table>
              </div>
              <div class="chart">
                <div
                  ref="rcwj"
                  class="echarts"></div>
              </div>
            </div>
          </div>
          <!-- <div class="item-box">
            <Title title="晨午检完成趋势" type="8"></Title>
            <div ref="cwjwcqs" class="echarts"></div>
          </div> -->
        </div>
        <div
          class="col-box"
          style="height: 50%">
          <div
            class="item-box"
            v-loading="rqjLoading">
            <Title
              :title="nowDate + '请假'"
              type="3"></Title>
            <div class="chart-layout">
              <div class="table">
                <el-table
                  :data="leaveNums"
                  :header-cell-style="{
                    background: '#ebf2ff',
                    fontSize: '2.8rem',
                    fontWeight: '100',
                    height: '8rem',
                  }"
                  @row-click="leaveNumsClick">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column
                    prop="sNums"
                    label="事假"></el-table-column>
                  <el-table-column
                    prop="bNums"
                    label="病假"></el-table-column>
                </el-table>
              </div>
              <div class="chart">
                <div
                  ref="rqj"
                  class="echarts"></div>
              </div>
            </div>
            <!--  <el-table
              :data="leaveNums"
              @row-click="leaveNumsClick"
              :header-cell-style="{ background: '#edf5ff' }">
              <el-table-column prop="name">
                <template slot-scope="scope">
                  <span
                    :class="
                      scope.row.name < 0
                        ? 'font-class-weight'
                        : 'font-class-weight'
                    ">
                    {{ scope.row.name }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="sNums"
                label="事假"></el-table-column>
              <el-table-column
                prop="bNums"
                label="病假"></el-table-column>
            </el-table> -->
          </div>
          <!-- <div class="item-box">
            <Title title="xxxx" type="9"></Title>
            <div
              ref="cqqkqs"
              class="echarts"></div>
          </div> -->
        </div>
        <div class="l-b">
          <div
            class="item-box"
            v-loading="cwjqsLoading">
            <Title
              title="晨午检完成趋势"
              type="7"></Title>
            <div
              ref="cwjwcqs"
              class="echarts"></div>
          </div>
          <div
            class="item-box"
            v-loading="cqqkLoading">
            <Title
              title="出勤情况趋势"
              type="8"></Title>
            <div
              ref="cqqkqs"
              class="echarts"></div>
          </div>
        </div>
        <div class="col-box wcj">
          <div class="item-box">
            <div class="center">
              <div class="top">
                <div class="totals">
                  <div class="total">
                    <div
                      class="icon"
                      style="
                        width: 40px;
                        height: 8px;
                        background: #ff985a;
                        border-radius: 4px;
                      "></div>
                    <div class="title">学校机构数</div>
                    <div class="num">
                      {{
                        zhxx.filter((item) => item.fieldName == '学校数量')[0]
                          .number
                      }}
                    </div>
                  </div>
                  <img src="../../assets/statisitics/bg1.png" />
                </div>
                <div class="totals">
                  <div class="total">
                    <div
                      class="icon"
                      style="
                        width: 40px;
                        height: 8px;
                        background: #10b27b;
                        border-radius: 4px;
                      "></div>
                    <div class="title">在校学生数</div>
                    <div class="num">
                      {{
                        zhxx.filter((item) => item.fieldName == '学生数量')[0]
                          .number
                      }}
                    </div>
                  </div>
                  <img src="../../assets/statisitics/bg2.png" />
                </div>
                <div class="totals">
                  <div class="total">
                    <div
                      class="icon"
                      style="
                        width: 40px;
                        height: 8px;
                        background: #5696ff;
                        border-radius: 4px;
                      "></div>
                    <div class="title">教师数量</div>
                    <div class="num">
                      {{
                        zhxx.filter((item) => item.fieldName == '教师数量')[0]
                          .number
                      }}
                    </div>
                  </div>
                  <img src="../../assets/statisitics/bg3.png" />
                </div>
                <div class="totals">
                  <div class="total">
                    <div
                      class="icon"
                      style="
                        width: 40px;
                        height: 8px;
                        background: #fe6463;
                        border-radius: 4px;
                      "></div>
                    <div class="title">传染病数量</div>
                    <div class="num">
                      {{
                        zhxx.filter((item) => item.fieldName == '传染病数量')[0]
                          .number
                      }}
                    </div>
                  </div>
                  <img src="../../assets/statisitics/bg4.png" />
                </div>
                <div class="totals">
                  <div class="total">
                    <div
                      class="icon"
                      style="
                        width: 40px;
                        height: 8px;
                        background: #4e72ef;
                        border-radius: 4px;
                      "></div>
                    <div class="title">请假数量</div>
                    <div class="num">
                      {{
                        zhxx.filter((item) => item.fieldName == '请假数量')[0]
                          .number
                      }}
                    </div>
                  </div>
                  <img src="../../assets/statisitics/bg5.png" />
                </div>
              </div>
              <div
                class="bottom"
                v-loading="cwjssdtLoading">
                <Title
                  :title="
                    chenWuState == 1
                      ? '晨检实时动态监测'
                      : chenWuState == 2
                      ? '午检实时动态监测'
                      : '晚检实时动态监测'
                  "
                  type="9"></Title>
                <div
                  ref="cwjssdt"
                  class="echarts"></div>
              </div>
            </div>
            <!-- <div
              ref="cwjssdt"
              class="echarts"></div> -->
          </div>
        </div>
        <div class="col-box">
          <div
            class="item-box"
            v-loading="crbfbLoading">
            <Title
              :title="nowDate + '传染病分布'"
              type="4"></Title>
            <div
              ref="crbfb"
              class="echarts"></div>
          </div>
          <div
            class="item-box"
            v-loading="jyzcrbqsLoading">
            <Title
              title="近一周传染病趋势"
              type="10"></Title>
            <div
              ref="jyzcrbqs"
              class="echarts"></div>
          </div>
        </div>
        <div class="col-box">
          <div
            class="item-box"
            v-loading="rcgbLoading">
            <Title
              :title="nowDate + '常规病排名'"
              type="5"></Title>
            <div
              ref="jyzptb"
              class="echarts"></div>
          </div>
          <div
            class="item-box"
            v-loading="jyzbqqsLoading">
            <Title
              title="近一周病情趋势"
              type="11"></Title>
            <div
              ref="jyzbqqs"
              class="echarts"></div>
          </div>
        </div>
        <div class="col-box">
          <div
            class="item-box"
            v-loading="rzzLoading">
            <Title
              :title="nowDate + '症状情况'"
              type="6"></Title>
            <div
              ref="rzzpm"
              class="echarts"></div>
          </div>
          <div
            class="item-box"
            v-loading="jyzbzqsLoading">
            <Title
              title="近一周病症趋势"
              type="12"></Title>
            <div
              ref="jyzbzqs"
              class="echarts"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- </ScaleBox> -->
  </div>
</template>
<script>
// import { fontChart } from '@/utils/echartPxToRem'
// import ScaleBox from 'vue2-scale-box';
import * as echarts from 'echarts';
import Title from './components/Title2.vue';
import Dialog from './components/Dialog.vue';
import axios from 'axios';
import dayjs from 'dayjs';
import {
  getToken,
  baseInformation,
  dynamicMonitoring,
  dayLeaveCheckReport,
  dayLeaveReport,
  comprehensiveDataReport,
  dayInfectiousDiseaseData,
  dayConventionalDiseaseData,
  daySymptomData,
  stuLeaveCompletionTrendData,
  attendanceTrendsData,
  diseaseTrendData,
  conventionalDiseaseTrendsData,
  symptomDataTrendsData,
} from '@/api/statisitics/yzt';
// import {
//   getBaseInfo,
//   getCheckNums,
//   getLeaveNums,
//   getLnfect,
//   getNormal,
//   getSymptom,
//   getNormalT,
//   getLnfectT,
//   getFinishTrend,
//   getHaveTo,
//   getIll,
//   getSym,
//   getTrends,
// } from '@/api/statisitics/index';
export default {
  data() {
    return {
      isFullscreen: false,
      loading: false,
      baseLoading: false,
      cwjLoading: false,
      rqjLoading: false,
      crbfbLoading: false,
      rcgbLoading: false,
      rzzLoading: false,
      cwjqsLoading: false,
      cqqkLoading: false,
      cwjssdtLoading: false,
      jyzcrbqsLoading: false,
      jyzbqqsLoading: false,
      jyzbzqsLoading: false,
      dialogType: '',
      dialogVisible: false,
      dialogParam: {
        title: '',
        width: '40%',
        info: '',
      },
      boxWidth: '',
      boxHeight: '',
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ],
      timer: null, //时间定时器
      token: '',
      timepiece: 0, //计时器
      refreshTime: 2, //刷新时间（秒）
      chenWuState: 1, //1晨2午3晚
      weatcherData: {}, //天气数据
      baseInfo: [], //基础信息
      dynamicMonitoring: [], //晨午检实时动态
      checkNums: [], //日晨午检
      leaveNums: [], //日请假
      crbfb: [], //传染病分布
      rcgb: [], //日常规病
      rzz: [], //日症状
      zhxx: [
        {
          number: 0,
          fieldName: '学校数量',
        },
        {
          number: 0,
          fieldName: '学生数量',
        },
        {
          number: 0,
          fieldName: '教师数量',
        },
        {
          number: 0,
          fieldName: '请假数量',
        },
        {
          number: 0,
          fieldName: '传染病数量',
        },
      ], //综合信息
      cwjwcqs: [], //晨午检完成趋势
      cqqkqs: [], //出勤情况趋势
      jyzcrbqs: [], //近一周传染病趋势
      jyzbqqs: [], //近一周病情趋势
      jyzbzqs: [], //近一周病症趋势
      timer2: null, //动态数据定时器
      config: {
        appId: 'S20232002',
        appSecret: '300cc0a242577bb0d78bcabf339ca08d',
        timestamp: new Date().getTime(),
        header: '',
      },
      nowDate: dayjs(this.getFirstDayAndLastDay().endDate).format('M月D日'),
    };
  },
  components: {
    Title,
    Dialog,
    // ScaleBox,
  },
  created() {
    this.boxWidth = window.innerWidth + 'px';
    this.boxHeight = window.innerHeight + 'px';
  },
  mounted() {
    //调用监听事件
    window.addEventListener('keydown', this.KeyDown, true);

    //获取最近7天日期

    // getDay(0);//当天日期

    // getDay(-7);//7天前日期

    // //获取最近3天日期

    // getDay(0);//当天日期

    // getDay(-3);//3天前日期
    this.timer = setInterval(() => {
      const date = dayjs(new Date());
      this.dateDay = date.format('HH:mm:ss');
      this.dateYear = date.format('YYYY-MM-DD');
      this.dateWeek = date.format(this.weekday[date.day()]);
    }, 1000);
    //判断晨午晚检时间
    this.getTimeState();
    this.init();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.timer2) {
      clearInterval(this.timer2);
    }
  },
  methods: {
    KeyDown(event) {
      if (event.keyCode === 122) {
        location.reload();
      }
    },
    // 计算起始日期的中间日期
    getAllDate(start, end, type) {
      let format = (time) => {
        let ymd = '';
        let mouth = time.getMonth() + 1;
        let day = time.getDate() >= 10 ? time.getDate() : '0' + time.getDate();
        let hour =
          time.getHours() >= 10 ? time.getHours() : '0' + time.getHours();
        ymd += time.getFullYear() + '-'; // 获取年份。
        ymd += mouth + '-'; // 获取月份。
        ymd += day; // 获取日。
        if (type === 1) {
          ymd += ' ' + hour + ':00'; // 获取小时。
        }
        //m.df格式
        return ymd; // 返回日期。
      };
      let dateArr = [];
      let startArr = start.split('-');
      let endArr = end.split('-');
      let db = new Date();
      db.setUTCFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      if (type === 1) {
        db.setHours(startArr[3]);
      } else {
        db.setHours(0, 0, 0, 0);
      }
      let de = new Date();
      de.setUTCFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      if (type === 1) {
        de.setHours(Number(endArr[3]) + 1);
      } else {
        de.setHours(0, 0, 0, 0);
      }
      let unixDb = db.getTime();
      let unixDe = de.getTime();
      let stamp;
      let oneDay = null;
      if (type === 1) {
        oneDay = 60 * 60 * 1000;
      } else if (type === 2) {
        oneDay = 24 * 60 * 60 * 1000;
      } else if (type === 3) {
        oneDay = 24 * 60 * 60 * 1000 * 7;
      }
      for (stamp = unixDb; stamp <= unixDe; ) {
        if (
          new Date(format(new Date(parseInt(stamp)))).getDay() < 6 &&
          new Date(format(new Date(parseInt(stamp)))).getDay() != 0
        ) {
          dateArr.push(dayjs(new Date(parseInt(stamp))).format('YYYY-MM-DD'));
        }

        stamp = stamp + oneDay;
      }
      return dateArr;
    },
    getDay(day) {
      var today = new Date();

      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;

      today.setTime(targetday_milliseconds); //注意，这行是关键代码

      var tYear = today.getFullYear();

      var tMonth = today.getMonth();

      var tDate = today.getDate();

      tMonth = this.doHandleMonth(tMonth + 1);

      tDate = this.doHandleMonth(tDate);

      return tYear + '-' + tMonth + '-' + tDate;
    },

    doHandleMonth(month) {
      var m = month;

      if (month.toString().length == 1) {
        m = '0' + month;
      }

      return m;
    },
    //判断本周第一天和最后一天
    getFirstDayAndLastDay() {
      // let date = new Date();
      // var weekday = date.getDay() || 7;
      // let monday = date.setDate(date.getDate() - weekday + 1);
      // let sunday = '';
      // if (weekday < 6) {
      //   sunday = new Date();
      // } else {
      //   sunday = new Date(
      //     date.getTime() +
      //       3600 * 1000 * 24 * (date.getDay() == 0 ? 0 : 7 - date.getDay() - 2)
      //   );
      // }
      return {
        startDate: this.getDay(-6),
        endDate: this.getDay(0),
        allDate: this.getAllDate(this.getDay(-6), this.getDay(0), 2),
        // startDate: dayjs(monday).format('YYYY-MM-DD'),
        // endDate: dayjs(sunday).format('YYYY-MM-DD'),
      };
    },
    //判断是否全屏
    fullScreenChangeFn() {
      return (
        document.fullscreenElement ||
        document.msFullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        false
      );
    },
    //数据处理
    dataResort(arr, check_date, check_type_name, checked_num) {
      let newArr = [];
      arr.forEach((item) => {
        let index = -1;
        let isExists = newArr.some((newItem, j) => {
          if (item[check_date] == newItem.check_date) {
            index = j;
            return true;
          }
        });
        if (!isExists) {
          newArr.push({
            check_date: item[check_date],
            subList: [item[check_type_name]],
            nums: [item[checked_num]],
          });
        } else {
          newArr[index].subList.push(item[check_type_name]);
          newArr[index].nums.push(item[checked_num]);
        }
      });
      return newArr;
    },
    async init() {
      // this.loading = true;
      this.baseLoading = true;
      this.cwjLoading = true;
      this.rqjLoading = true;
      this.crbfbLoading = true;
      this.rcgbLoading = true;
      this.rzzLoading = true;
      this.cwjqsLoading = true;
      this.cqqkLoading = true;
      this.cwjssdtLoading = true;
      this.jyzcrbqsLoading = true;
      this.jyzbqqsLoading = true;
      this.jyzbzqsLoading = true;
      //获取token
      const { data: token } = await getToken({
        appId: 'S20232002',
        appSecret: '300cc0a242577bb0d78bcabf339ca08d',
        timestamp: new Date().getTime(),
      });
      this.config.header = token;
      //获取基本情况
      const { data: baseInfo } = await baseInformation({
        ...this.config,
      });
      this.baseInfo = eval(baseInfo);
      //基本情况
      this.initJbqk();
      this.baseLoading = false;
      //获取日晨午检
      const { data: rcwj } = await dayLeaveCheckReport({
        ...this.config,
        date: this.getFirstDayAndLastDay().endDate,
      });
      let checkNums = [
        { name: '小学', chenNums: 0, wuNums: 0, wanNums: 0 },
        { name: '中学', chenNums: 0, wuNums: 0, wanNums: 0 },
        { name: '高中', chenNums: 0, wuNums: 0, wanNums: 0 },
        { name: '幼儿园', chenNums: 0, wuNums: 0, wanNums: 0 },
        { name: '特教学校', chenNums: 0, wuNums: 0, wanNums: 0 },
      ];
      eval(rcwj).map((item) => {
        if (item.period == '小学' && item.check_type_name == '晨检') {
          checkNums[0].chenNums = item.checked_num;
        }
        if (item.period == '小学' && item.check_type_name == '午检') {
          checkNums[0].wuNums = item.checked_num;
        }
        if (item.period == '小学' && item.check_type_name == '晚检') {
          checkNums[0].wanNums = item.checked_num;
        }
        if (item.period == '中学' && item.check_type_name == '晨检') {
          checkNums[1].chenNums = item.checked_num;
        }
        if (item.period == '中学' && item.check_type_name == '午检') {
          checkNums[1].wuNums = item.checked_num;
        }
        if (item.period == '中学' && item.check_type_name == '晚检') {
          checkNums[1].wanNums = item.checked_num;
        }
        if (item.period == '高中' && item.check_type_name == '晨检') {
          checkNums[2].chenNums = item.checked_num;
        }
        if (item.period == '高中' && item.check_type_name == '午检') {
          checkNums[2].wuNums = item.checked_num;
        }
        if (item.period == '高中' && item.check_type_name == '晚检') {
          checkNums[2].wanNums = item.checked_num;
        }
        if (item.period == '幼儿园' && item.check_type_name == '晨检') {
          checkNums[3].chenNums = item.checked_num;
        }
        if (item.period == '幼儿园' && item.check_type_name == '午检') {
          checkNums[3].wuNums = item.checked_num;
        }
        if (item.period == '幼儿园' && item.check_type_name == '晚检') {
          checkNums[3].wanNums = item.checked_num;
        }
        if (item.period == '特教学校' && item.check_type_name == '晨检') {
          checkNums[4].chenNums = item.checked_num;
        }
        if (item.period == '特教学校' && item.check_type_name == '午检') {
          checkNums[4].wuNums = item.checked_num;
        }
        if (item.period == '特教学校' && item.check_type_name == '晚检') {
          checkNums[4].wanNums = item.checked_num;
        }
      });
      this.checkNums = checkNums;
      //日晨午检
      this.initRcwj();
      this.cwjLoading = false;
      // //获取日请假
      const { data: rqj } = await dayLeaveReport({
        ...this.config,
        date: this.getFirstDayAndLastDay().endDate,
      });
      let leaveNums = [
        { name: '小学', sNums: 0, bNums: 0 },
        { name: '初中', sNums: 0, bNums: 0 },
        { name: '高中', sNums: 0, bNums: 0 },
        { name: '幼儿园', sNums: 0, bNums: 0 },
        { name: '特教学校', sNums: 0, bNums: 0 },
      ];
      eval(rqj).map((item) => {
        if (item.period == '小学') {
          leaveNums[0].sNums = item.personal_leave_nu;
          leaveNums[0].bNums = item.sick_leave_num;
        }
        if (item.period == '中学') {
          leaveNums[1].sNums = item.personal_leave_nu;
          leaveNums[1].bNums = item.sick_leave_num;
        }
        if (item.period == '高中') {
          leaveNums[2].sNums = item.personal_leave_nu;
          leaveNums[2].bNums = item.sick_leave_num;
        }
        if (item.period == '幼儿园') {
          leaveNums[3].sNums = item.personal_leave_nu;
          leaveNums[3].bNums = item.sick_leave_num;
        }
        if (item.period == '特教学校') {
          leaveNums[4].sNums = item.personal_leave_nu;
          leaveNums[4].bNums = item.sick_leave_num;
        }
      });
      this.leaveNums = leaveNums;
      //日请假
      this.initRqj();
      this.rqjLoading = false;
      //获取基础数据
      const { data: jcsj } = await comprehensiveDataReport({
        ...this.config,
        startDate: this.getFirstDayAndLastDay().endDate,
        endDate: this.getFirstDayAndLastDay().endDate,
      });
      this.zhxx = eval(jcsj);
      let crbfbDatas = [
        { illnessName: '甲流', illnessNumber: 0 },
        { illnessName: '新冠病毒感染', illnessNumber: 0 },
        { illnessName: '流行性感冒', illnessNumber: 0 },
        { illnessName: '流行性腮腺炎', illnessNumber: 0 },
        { illnessName: '诺如病毒感染', illnessNumber: 0 },
        { illnessName: '疱疹性咽峡炎', illnessNumber: 0 },
        { illnessName: '水痘', illnessNumber: 0 },
      ];
      //获取日传染病分布
      const { data: crbfb } = await dayInfectiousDiseaseData({
        ...this.config,
        date: this.getFirstDayAndLastDay().endDate,
      });
      crbfbDatas.map((item1) => {
        eval(crbfb).map((item2) => {
          if (item1.illnessName == item2.illnessName) {
            item1.illnessNumber = item2.illnessNumber;
          }
        });
        this.crbfb.push(item1);
      });
      //传染病分布
      this.initCrbfb();
      this.crbfbLoading = false;
      //获取日常规病
      let rcgbDatas = [
        { illnessName: '普通感冒', illnessNumber: 0 },
        { illnessName: '其他普通疾病', illnessNumber: 0 },
        { illnessName: '胃肠炎', illnessNumber: 0 },
        { illnessName: '过敏性皮炎', illnessNumber: 0 },
        { illnessName: '湿疹', illnessNumber: 0 },
        { illnessName: '支气管炎', illnessNumber: 0 },
        { illnessName: '意外伤害', illnessNumber: 0 },
        // { illnessName: '病因不明', illnessNumber: 0 },
        { illnessName: '耳鼻喉疾病', illnessNumber: 0 },
      ];
      const { data: rcgb } = await dayConventionalDiseaseData({
        ...this.config,
        date: this.getFirstDayAndLastDay().endDate,
      });
      rcgbDatas.map((item1) => {
        eval(rcgb).map((item2) => {
          if (item1.illnessName == item2.illnessName) {
            item1.illnessNumber = item2.illnessNumber;
          }
        });
        this.rcgb.push(item1);
      });
      //日常规病
      this.initJyzptb();
      this.rcgbLoading = false;
      //获取日症状情况
      const { data: rzz } = await daySymptomData({
        ...this.config,
        date: this.getFirstDayAndLastDay().endDate,
      });
      let datas = [
        { illnessName: '咳嗽', illnessNumber: 0 },
        { illnessName: '发烧', illnessNumber: 0 },
        { illnessName: '腹泻', illnessNumber: 0 },
        { illnessName: '呕吐', illnessNumber: 0 },
        { illnessName: '流鼻涕', illnessNumber: 0 },
        { illnessName: '头晕', illnessNumber: 0 },
        { illnessName: '上呼吸道感染', illnessNumber: 0 },
      ];
      datas.map((item1) => {
        eval(rzz).map((item2) => {
          if (item1.illnessName == item2.illnessName) {
            item1.illnessNumber = item2.illnessNumber;
          }
        });
        this.rzz.push(item1);
      });
      //日症状情况
      this.initRzzpm();
      this.rzzLoading = false;
      //晨午检完成趋势
      const { data: wcqs } = await stuLeaveCompletionTrendData({
        ...this.config,
        ...this.getFirstDayAndLastDay(),
      });

      let cwjwcqsArr = this.dataResort(
        eval(wcqs),
        'check_date',
        'check_type_name',
        'checked_num'
      );
      cwjwcqsArr.map((item1) => {
        if (item1.subList.indexOf('晨检') == -1) {
          item1.subList.push('晨检');
          item1.nums.push(0);
        }
        if (item1.subList.indexOf('午检') == -1) {
          item1.subList.push('午检');
          item1.nums.push(0);
        }
        if (item1.subList.indexOf('晚检') == -1) {
          item1.subList.push('晚检');
          item1.nums.push(0);
        }
      });
      //统一排序
      cwjwcqsArr.map((item) => {
        this.cwjwcqs.push({
          check_date: item.check_date,
          subList: ['晨检', '午检', '晚检'],
          nums: [
            item.nums[item.subList.indexOf('晨检')],
            item.nums[item.subList.indexOf('午检')],
            item.nums[item.subList.indexOf('晚检')],
          ],
        });
      });
      // cwjwcqsArr.map((item) => {
      //   let nums = item.nums.reduce((x, y) => {
      //     return x + y;
      //   });
      //   if(nums>0){
      //     this.cwjwcqs.push(item);
      //   }
      // });
      //晨午检完成趋势
      this.initCwjwcqsChart();
      this.cwjqsLoading = false;
      //出勤情况趋势
      const { data: cqqkqs } = await attendanceTrendsData({
        ...this.config,
        ...this.getFirstDayAndLastDay(),
      });
      this.cqqkqs = this.dataResort(
        eval(cqqkqs),
        'check_date',
        'actual_num',
        'should_num'
      );
      //出勤情况趋势
      this.initCqqkqs();
      this.cqqkLoading = false;
      //近一周传染病趋势
      const { data: jyzcrbqs } = await diseaseTrendData({
        ...this.config,
        ...this.getFirstDayAndLastDay(),
      });
      let crbqsDatas = this.dataResort(
        eval(jyzcrbqs),
        'date',
        'illnessNumber',
        'illnessName'
      );
      let crb = [
        { name: '甲流' },
        { name: '新冠病毒感染' },
        { name: '水痘' },
        { name: '流行性感冒' },
        { name: '流行性腮腺炎' },
        // { name: '诺如病毒感染' },
        // { name: '痕疹性咽峡炎' },
      ];
      let newCrbArray = [];
      crbqsDatas.map((item1) => {
        this.getFirstDayAndLastDay().allDate.map((date) => {
          crb.map((item2) => {
            newCrbArray.push({
              date: item1.check_date,
              illnessName: item2.name,
              illnessNumber:
                item1.nums.indexOf(item2.name) > -1
                  ? item1.subList[item1.nums.indexOf(item2.name)]
                  : 0,
            });
          });
        });
      });

      let jyzcrbqsArr = this.dataResort(
        eval(newCrbArray),
        'date',
        'illnessNumber',
        'illnessName'
      );
      //数据处理(去除周末，补全缺少日期)
      if (jyzcrbqsArr.length > 0) {
        this.getFirstDayAndLastDay().allDate.map((date) => {
          let value = jyzcrbqsArr.filter((item) => item.check_date == date);
          if (value.length > 0) {
            this.jyzcrbqs.push(value[0]);
          } else {
            this.jyzcrbqs.push({
              check_date: date,
              nums: crb.map((item) => {
                return item.name;
              }),
              subList: crb.map((item) => {
                return 0;
              }),
            });
          }
        });
      } else {
        this.getFirstDayAndLastDay().allDate.map((date) => {
          this.jyzcrbqs.push({
            check_date: date,
            nums: crb.map((item) => {
              return item.name;
            }),
            subList: crb.map((item) => {
              return 0;
            }),
          });
        });
      }

      //近一周传染病趋势
      this.initJyzcrbqs();
      this.jyzcrbqsLoading = false;
      //近一周病情趋势
      let bqqs = [
        {
          name: '普通感冒',
        },
        {
          name: '胃肠炎',
        },
        {
          name: '支气管炎',
        },
        {
          name: '耳鼻喉疾病',
        },
        {
          name: '过敏性皮炎',
        },
        {
          name: '意外伤害',
        },
      ];
      const { data: jyzbqqs } = await conventionalDiseaseTrendsData({
        ...this.config,
        ...this.getFirstDayAndLastDay(),
      });
      let bqqsData = this.dataResort(
        eval(jyzbqqs),
        'date',
        'illnessNumber',
        'illnessName'
      );
      let newbqqsArray = [];
      bqqsData.map((item1) => {
        bqqs.map((item2) => {
          newbqqsArray.push({
            date: item1.check_date,
            illnessName: item2.name,
            illnessNumber:
              item1.nums.indexOf(item2.name) > -1
                ? item1.subList[item1.nums.indexOf(item2.name)]
                : 0,
          });
        });
      });
      let jyzbqqsArr = this.dataResort(
        eval(newbqqsArray),
        'date',
        'illnessNumber',
        'illnessName'
      );
      //数据处理(去除周末，补全缺少日期)
      if (jyzbqqsArr.length > 0) {
        this.getFirstDayAndLastDay().allDate.map((date) => {
          let value = jyzbqqsArr.filter((item) => item.check_date == date);
          if (value.length > 0) {
            this.jyzbqqs.push(value[0]);
          } else {
            this.jyzbqqs.push({
              check_date: date,
              nums: bqqs.map((item) => {
                return item.name;
              }),
              subList: bqqs.map((item) => {
                return 0;
              }),
            });
          }
        });
      } else {
        this.getFirstDayAndLastDay().allDate.map((date) => {
          this.jyzbqqs.push({
            check_date: date,
            nums: bqqs.map((item) => {
              return item.name;
            }),
            subList: bqqs.map((item) => {
              return 0;
            }),
          });
        });
      }
      //近一周病情趋势
      this.initJyzbqqs();
      this.jyzbqqsLoading = false;
      //近一周病情症趋势
      let bzqs = [
        {
          name: '咳嗽',
        },
        {
          name: '发烧',
        },
        {
          name: '腹泻',
        },
        {
          name: '呕吐',
        },
        {
          name: '流鼻涕',
        },
        {
          name: '头晕',
        },
        {
          name: '咽痛',
        },
        {
          name: '四肢无力',
        },
      ];
      const { data: jyzbzqs } = await symptomDataTrendsData({
        ...this.config,
        ...this.getFirstDayAndLastDay(),
      });
      let bzqsData = this.dataResort(
        eval(jyzbzqs),
        'date',
        'illnessNumber',
        'illnessName'
      );
      let newbzqsArray = [];
      bzqsData.map((item1) => {
        bzqs.map((item2) => {
          newbzqsArray.push({
            date: item1.check_date,
            illnessName: item2.name,
            illnessNumber:
              item1.nums.indexOf(item2.name) > -1
                ? item1.subList[item1.nums.indexOf(item2.name)]
                : 0,
          });
        });
      });
      let jyzbzqsArr = this.dataResort(
        eval(newbzqsArray),
        'date',
        'illnessNumber',
        'illnessName'
      );
      //数据处理(去除周末，补全缺少日期)
      if (jyzbzqsArr.length > 0) {
        this.getFirstDayAndLastDay().allDate.map((date) => {
          let value = jyzbzqsArr.filter((item) => item.check_date == date);
          if (value.length > 0) {
            this.jyzbzqs.push(value[0]);
          } else {
            this.jyzbzqs.push({
              check_date: date,
              nums: bzqs.map((item) => {
                return item.name;
              }),
              subList: bzqs.map((item) => {
                return 0;
              }),
            });
          }
        });
      } else {
        this.getFirstDayAndLastDay().allDate.map((date) => {
          this.jyzbzqs.push({
            check_date: date,
            nums: bzqs.map((item) => {
              return item.name;
            }),
            subList: bzqs.map((item) => {
              return 0;
            }),
          });
        });
      }
      //近一周病症趋势
      this.jyzbzqsLoading = false;
      this.initJyzbzqs();
      // //获取天气
      // //this.getWeather();
      //晨午检实时动态
      this.initCwjssdt();

      // this.loading = false;
    },
    //获取or刷新token
    async getToken() {
      const { data } = await getToken({
        appId: 'S20232002',
        appSecret: '300cc0a242577bb0d78bcabf339ca08d',
        timestamp: new Date().getTime(),
      });
      this.config.header = data;
    },
    //获取时间
    getTimeState() {
      // 获取当前时间
      let timeNow = new Date();
      // 获取当前小时
      let hours = timeNow.getHours();
      // 判断当前时间段
      if (hours >= 7 && hours < 11) {
        this.chenWuState = 1;
      } else if (hours >= 11 && hours < 16) {
        this.chenWuState = 2;
      } else {
        this.chenWuState = 3;
      }
    },
    getWeather() {
      // 第三方天气api接口
      axios
        .get('https://v0.yiketianqi.com/api', {
          params: {
            unescape: '1',
            appid: '51385758',
            appsecret: '1PDw7ktP ',
            version: 'v61',
            city: '成都',
          },
        })
        .then((res) => {
          // console.log(res);
          if (res) {
            this.weatcherData = res;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //传染病分布
    initCrbfb() {
      let chartColumn = echarts.init(this.$refs.crbfb);
      let option = {
        color: ['#fdb55b'],
        radar: [
          {
            indicator: this.crbfb.map((item) => {
              return {
                text: item.illnessName,
                max: Math.max.apply(
                  Math,
                  this.crbfb.map(function (o) {
                    return o.illnessNumber * 1.1;
                  })
                ),
              };
            }),
            name: {
              textStyle: {
                fontSize: 44, //外圈标签字体大小
                color: '#5b81cb', //外圈标签字体颜色
              },
            },
            triggerEvent: true,
            center: ['50%', '53%'],
            radius: 130,
            axisName: {
              color: '#09246B',
              borderRadius: 3,
              padding: [3, 5],
              fontSize: '3rem',
            },
          },
        ],
        series: [
          {
            type: 'radar',
            radarIndex: 0,
            data: [
              {
                value: this.crbfb.map((item) => {
                  return item.illnessNumber;
                }),
                areaStyle: {
                  color: new echarts.graphic.RadialGradient(0.1, 0.7, 0.9, [
                    {
                      color: 'rgba(250,210,55,0.1)',
                      offset: 0,
                    },
                    {
                      color: 'rgba(254,154,30, 0.9)',
                      offset: 1,
                    },
                  ]),
                },
              },
            ],
          },
        ],
      };
      chartColumn.setOption(option);
      chartColumn.on('click', (param) => {
        this.dialogVisible = true;
        this.dialogType = 4;
        this.dialogParam.title = this.nowDate + param.name + '统计';
        this.$refs['dialog'].getIllNums(param.name);
      });
    },
    //近一周病症趋势
    initJyzbzqs() {
      let chartColumn = echarts.init(this.$refs.jyzbzqs);
      let option = {
        color: ['#FAD237', '#10B27B', '#7CB8FF', '#3870FF', '#FEAF5B'],
        tooltip: {
          trigger: 'axis',
          confine: true,
          textStyle: {
            fontSize: '24',
          },
        },
        legend: {
          data: this.jyzbzqs[0].nums,
          left: 'right',
          top: '5%',
          icon: 'rect',
          textStyle: {
            fontSize: '2rem',
            color: '#09246B',
          },
          itemHeight: 4,
          itemWidth: 12,
        },
        grid: {
          left: '12%',
          top: '20%',
          right: '10%',
          bottom: '19%',
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: this.jyzbzqs.map((item) => {
              return (
                item.check_date.split('-')[1] +
                '/' +
                item.check_date.split('-')[2]
              );
            }),
            axisLabel: {
              interval: 0,
              textStyle: {
                fontSize: '2.6rem',
                color: '#09246B',
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true,
            },
            axisLabel: {
              textStyle: {
                fontSize: '3rem',
                color: '#09246B',
              },
            },
          },
        ],
        series: [
          {
            name: '咳嗽',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FAD237',
                },
                {
                  offset: 1,
                  color: '#FAD237',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[0];
            }),
          },
          {
            name: '发烧',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#10B27B',
                },
                {
                  offset: 1,
                  color: '#10B27B',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[1];
            }),
          },
          {
            name: '腹泻',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#7CB8FF',
                },
                {
                  offset: 1,
                  color: '#7CB8FF',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[2];
            }),
          },
          {
            name: '呕吐',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#7CB8FF',
                },
                {
                  offset: 1,
                  color: '#7CB8FF',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[3];
            }),
          },
          {
            name: '流鼻涕',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#fff8df',
                },
                {
                  offset: 1,
                  color: '#fff8df',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[4];
            }),
          },
          {
            name: '头晕',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#cd4d17',
                },
                {
                  offset: 1,
                  color: '#cd4d17',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[5];
            }),
          },
          {
            name: '咽痛',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#7fa2ff',
                },
                {
                  offset: 1,
                  color: '#7fa2ff',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[6];
            }),
          },
          {
            name: '四肢无力',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#0eb17a',
                },
                {
                  offset: 1,
                  color: '#0eb17a',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[7];
            }),
          },
        ],
      };
      chartColumn.setOption(option);
    },
    //晨午检完成趋势
    initCwjwcqsChart() {
      if (this.cwjwcqs.length > 0) {
        let chartColumn = echarts.init(this.$refs.cwjwcqs);
        let option = {
          tooltip: {
            trigger: 'axis',
            confine: true,
            textStyle: {
              fontSize: '24',
            },
          },
          legend: {
            data: this.cwjwcqs[0].subList,
            left: 'right',
            top: '5%',
            icon: 'rect',
            itemHeight: 4,
            itemWidth: 12,
            textStyle: {
              fontSize: '2.6rem',
            },
          },
          grid: {
            left: '12%',
            top: '17%',
            right: '6%',
            bottom: '19%',
            // containLabel: true,
          },
          xAxis: {
            axisLabel: {
              textStyle: {
                fontSize: '3rem',
                color: '#09246B',
              },
            },
            type: 'category',
            boundaryGap: true,
            data: this.cwjwcqs.map((item) => {
              return (
                item.check_date.split('-')[1] +
                '/' +
                item.check_date.split('-')[2]
              );
            }),
          },
          yAxis: {
            axisLabel: {
              textStyle: {
                fontSize: '3rem',
                color: '#09246B',
              },
            },
            type: 'value',
            axisLine: {
              show: true,
            },
          },
          color: ['#10B27B', '#3870FF', '#FEAF5B'],
          series: [
            {
              name: this.cwjwcqs[0].subList[0],
              type: 'line',
              areaStyle: {
                normal: {
                  // 渐变填充色（线条下半部分）
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#10B27B',
                    },
                    {
                      offset: 1,
                      color: '#10B27B00',
                    },
                  ]),
                },
              },
              symbol: 'none',
              emphasis: {
                focus: 'series',
              },
              data: this.cwjwcqs.map((item) => {
                return item.nums[0];
              }),
            },
            {
              name: this.cwjwcqs[0].subList[1],
              type: 'line',
              areaStyle: {
                normal: {
                  // 渐变填充色（线条下半部分）
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#3870FF',
                    },
                    {
                      offset: 1,
                      color: '#3870FF00',
                    },
                  ]),
                },
              },
              symbol: 'none',
              emphasis: {
                focus: 'series',
              },
              data: this.cwjwcqs.map((item) => {
                return item.nums[1];
              }),
            },
            {
              name: this.cwjwcqs[0].subList[2],
              type: 'line',
              areaStyle: {
                normal: {
                  // 渐变填充色（线条下半部分）
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#FEAF5B',
                    },
                    {
                      offset: 1,
                      color: '#FEAF5B00',
                    },
                  ]),
                },
              },
              symbol: 'none',
              emphasis: {
                focus: 'series',
              },
              data: this.cwjwcqs.map((item) => {
                return item.nums[2];
              }),
            },
          ],
        };
        chartColumn.setOption(option);
      }
    },
    //出勤情况趋势
    initCqqkqs() {
      let chartColumn = echarts.init(this.$refs.cqqkqs);
      let option = {
        tooltip: {
          show: true,
          confine: true,
          textStyle: {
            fontSize: '24',
          },
        },
        legend: {
          data: ['应到人数', '实到人数'],
          left: 'right',
          top: '5%',
          // icon: 'rect',
          // itemHeight: 4,
          // itemWidth: 12,
          textStyle: {
            fontSize: '2.6rem',
          },
        },
        grid: {
          left: '12%',
          top: '17%',
          right: '6%',
          bottom: '19%',
          // containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.cqqkqs.map((item) => {
              return (
                item.check_date.split('-')[1] +
                '/' +
                item.check_date.split('-')[2]
              );
            }),
            axisLabel: {
              textStyle: {
                fontSize: '3rem',
                color: '#09246B',
              },
            },
            axisPointer: {
              type: 'shadow',
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            // max: 150000,
            axisLabel: {
              textStyle: {
                fontSize: '3rem',
                color: '#09246B',
              },
            },
            axisLine: {
              show: true,
            },
          },
          {
            type: 'value',
            name: '实到人数',
            show: false,
            min: 0,
            // max: 150000,
          },
        ],
        series: [
          {
            name: '应到人数',
            type: 'bar',
            barWidth: '40%',
            itemStyle: {
              barBorderRadius: [7, 7, 0, 0],
              color: '#3870FF',
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 人';
              },
            },
            data: this.cqqkqs.map((item) => {
              return item.nums.reduce(function (total, value) {
                return total + value;
              }, 0);
            }),
          },
          {
            name: '实到人数',
            type: 'line',
            smooth: true,
            itemStyle: {
              normal: {
                color: '#FEAF5B',
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 人';
              },
            },
            data: this.cqqkqs.map((item) => {
              return item.subList.reduce(function (total, value) {
                return total + value;
              }, 0);
            }),
          },
        ],
      };
      chartColumn.setOption(option);
    },
    //晨午检实时动态
    async initCwjssdt() {
      const { data } = await dynamicMonitoring({
        ...this.config,
        checkType: this.chenWuState,
      });
      this.cwjssdtLoading = false;
      let chartColumn = echarts.init(this.$refs.cwjssdt);
      let option = {
        xAxis: {
          max: 'dataMax',
          axisLine: {
            show: true,
          },
          axisLabel: {
            textStyle: {
              fontSize: '3rem',
              color: '#09246B',
            },
          },
        },
        tooltip: {
          textStyle: {
            fontSize: '24',
          },
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '23%',
          top: '6%',
          right: '8%',
          bottom: '13%',
        },
        yAxis: {
          type: 'category',
          data: eval(data).map((item) => {
            return item.schoolName;
          }),
          inverse: true,
          animationDuration: 300,
          animationDurationUpdate: 300,
          max: 5, // only the largest 3 bars will be displayed
          axisLabel: {
            textStyle: {
              fontSize: '3rem',
              color: '#09246B',
            },
            formatter: function (value) {
              var texts = value;
              if (texts.length > 8) {
                // 限制长度自设
                texts = texts.substr(0, 8) + '...';
              }
              return texts;
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            realtimeSort: true,
            name: '检测率',
            type: 'bar',
            color: '#7cb8ff',
            data: eval(data).map((item) => {
              return item.rateOfProgress;
            }),
            barWidth: '40%',
            label: {
              show: true,
              position: 'right',
              formatter: '{c}%',
              valueAnimation: true,
              textStyle: {
                fontSize: '2.4rem',
                color: '#09246B',
              },
            },
          },
        ],
        legend: {
          show: true,
          left: 'right',
          textStyle: {
            //图例文字的样式
            color: '#09246B',
            fontSize: '2.6rem',
          },
        },
        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear',
      };
      //获取数据2秒一次
      let run = () => {
        if (
          6 < parseInt(this.dateDay.split(':')[0]) &&
          parseInt(this.dateDay.split(':')[0]) < 22
        ) {
          //获取晨午检数据
          dynamicMonitoring({
            ...this.config,
            timestamp: new Date().getTime(),
            checkType: this.chenWuState,
          }).then((res) => {
            if (eval(res.data).length > 0) {
              let data = eval(res.data).map((item) => {
                return item.rateOfProgress;
              });
              chartColumn.setOption({
                series: [
                  {
                    type: 'bar',
                    data,
                  },
                ],
              });
            }
          });
        }
      };
      setTimeout(() => {
        run();
      }, 0);
      this.timer2 = setInterval(() => {
        this.timepiece += this.refreshTime;
        //大于1.5小时刷新token
        if (this.timepiece > 3600) {
          this.getToken();
          this.timepiece = 0;
        }
        //判断晨午晚
        this.getTimeState();
        run();
      }, this.refreshTime * 1000);
      chartColumn.setOption(option);
    },
    //近一周传染病趋势
    initJyzcrbqs() {
      let chartColumn = echarts.init(this.$refs.jyzcrbqs);
      let option = {
        tooltip: {
          trigger: 'axis',
          confine: true,
          textStyle: {
            fontSize: '24',
          },
        },
        legend: {
          data: this.jyzcrbqs[0].nums,
          left: 'right',
          top: '5%',
          icon: 'rect',
          textStyle: {
            fontSize: '2rem',
            color: '#09246B',
          },
          itemHeight: 4,
          itemWidth: 12,
        },
        grid: {
          left: '12%',
          top: '20%',
          right: '10%',
          bottom: '19%',
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.jyzcrbqs.map((item) => {
            return (
              item.check_date.split('-')[1] +
              '/' +
              item.check_date.split('-')[2]
            );
          }),
          axisLabel: {
            textStyle: {
              fontSize: '2.6rem',
              color: '#09246B',
            },
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
          },
          axisLabel: {
            textStyle: {
              fontSize: '3rem',
              color: '#09246B',
            },
          },
        },
        series: [
          {
            name: '甲流',
            smooth: true,
            symbolSize: 0,
            type: 'line',
            data: this.jyzcrbqs.map((item) => {
              return item.subList[0];
            }),
            itemStyle: {
              normal: {
                color: '#FAD237',
              },
            },
          },
          {
            name: '新冠病毒感染',
            type: 'line',
            smooth: true,
            symbolSize: 0,
            itemStyle: {
              normal: {
                color: '#10B27B',
              },
            },
            data: this.jyzcrbqs.map((item) => {
              return item.subList[1];
            }),
          },
          {
            name: '水痘',
            smooth: true,
            symbolSize: 0,
            type: 'line',
            data: this.jyzcrbqs.map((item) => {
              return item.subList[2];
            }),
            itemStyle: {
              normal: {
                color: '#7CB8FF',
              },
            },
          },
          {
            name: '流行性感冒',
            smooth: true,
            symbolSize: 0,
            type: 'line',
            data: this.jyzcrbqs.map((item) => {
              return item.subList[3];
            }),
            itemStyle: {
              normal: {
                color: '#3870FF',
              },
            },
          },
          {
            name: '流行性腮腺炎',
            smooth: true,
            symbolSize: 0,
            type: 'line',
            data: this.jyzcrbqs.map((item) => {
              return item.subList[4];
            }),
            itemStyle: {
              normal: {
                color: '#FEAF5B',
              },
            },
          },
          // {
          //   name: '诺如病毒感染',
          //   smooth: true,
          //   symbolSize: 0,
          //   type: 'line',
          //   data: this.jyzcrbqs.map((item) => {
          //     return item.subList[5];
          //   }),
          //   itemStyle: {
          //     normal: {
          //       color: '#10B27B',
          //     },
          //   },
          // },
          // {
          //   name: '痕疹性咽峡炎',
          //   smooth: true,
          //   symbolSize: 0,
          //   type: 'line',
          //   data: this.jyzbqqs.map((item) => {
          //     return item.subList[6];
          //   }),
          //   itemStyle: {
          //     normal: {
          //       color: '#10B27B',
          //     },
          //   },
          // },
        ],
      };
      chartColumn.setOption(option);
    },
    //近一周病情趋势
    initJyzbqqs() {
      let chartColumn = echarts.init(this.$refs.jyzbqqs);
      let option = {
        tooltip: {
          trigger: 'axis',
          confine: true,
          textStyle: {
            fontSize: '24',
          },
        },
        legend: {
          data: this.jyzbqqs[0].nums,
          left: 'right',
          top: '5%',
          icon: 'rect',
          textStyle: {
            fontSize: '2rem',
            color: '#09246B',
          },
          itemHeight: 4,
          itemWidth: 12,
        },
        grid: {
          left: '12%',
          top: '20%',
          right: '10%',
          bottom: '19%',
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.jyzbqqs.map((item) => {
            return (
              item.check_date.split('-')[1] +
              '/' +
              item.check_date.split('-')[2]
            );
          }),
          axisLabel: {
            textStyle: {
              fontSize: '2.6rem',
              color: '#09246B',
            },
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
          },
          axisLabel: {
            textStyle: {
              fontSize: '3rem',
              color: '#09246B',
            },
          },
        },
        series: [
          {
            name: '普通感冒',
            type: 'line',
            data: this.jyzbqqs.map((item) => {
              return item.subList[0];
            }),
            itemStyle: {
              normal: {
                color: '#7CB8FF',
                lineStyle: {
                  color: '#7CB8FF',
                },
              },
            },
          },
          {
            name: '肠胃炎',
            type: 'line',
            itemStyle: {
              normal: {
                color: '#3F80FF',
                lineStyle: {
                  color: '#3F80FF',
                },
              },
            },
            data: this.jyzbqqs.map((item) => {
              return item.subList[1];
            }),
          },
          {
            name: '支气管炎',
            type: 'line',
            data: this.jyzbqqs.map((item) => {
              return item.subList[2];
            }),
            itemStyle: {
              normal: {
                color: '#FEAF5B',
                lineStyle: {
                  color: '#FEAF5B',
                },
              },
            },
          },
          {
            name: '耳鼻喉疾病',
            type: 'line',
            data: this.jyzbqqs.map((item) => {
              return item.subList[3];
            }),
            itemStyle: {
              normal: {
                color: '#10B27B',
                lineStyle: {
                  color: '#10B27B',
                },
              },
            },
          },
          {
            name: '过敏性皮炎',
            type: 'line',
            data: this.jyzbqqs.map((item) => {
              return item.subList[4];
            }),
            itemStyle: {
              normal: {
                color: '#09246b',
                lineStyle: {
                  color: '#09246b',
                },
              },
            },
          },
          {
            name: '意外伤害',
            type: 'line',
            data: this.jyzbqqs.map((item) => {
              return item.subList[5];
            }),
            itemStyle: {
              normal: {
                color: '#fff39d',
                lineStyle: {
                  color: '#fff39d',
                },
              },
            },
          },
        ],
      };
      chartColumn.setOption(option);
    },
    initJyzrbqk() {
      let chartColumn = echarts.init(this.$refs.jyzrbqk);
      let option = {
        tooltip: {
          trigger: 'axis',
          confine: true,
          textStyle: {
            fontSize: '24',
          },
          axisPointer: {
            type: 'none',
          },
        },
        grid: {
          left: '26%',
          top: '8%',
          right: '17%',
          bottom: '3%',
        },
        xAxis: {
          show: false,
          type: 'value',
        },
        yAxis: {
          type: 'category',
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            margin: 76,
            textStyle: {
              align: 'left',
              color: '#09246B',
            },
          },
          data: this.lnfect.map((item) => {
            return item.illness_name;
          }),
        },
        series: [
          {
            name: '近一周传染病',
            type: 'bar',
            label: {
              normal: {
                show: true,
                formatter: '{c}人',
                position: 'right',
                textStyle: {
                  color: 'auto',
                  fontSize: 14,
                },
              },
            },
            barWidth: 12,
            data: this.lnfect.map((item) => {
              return item.nums;
            }),
            itemStyle: {
              normal: {
                //这里设置每个柱子颜色不一样
                color: function (params) {
                  // 定义一个颜色数组colorList
                  var colorList = [
                    '#FE9A1E',
                    '#3870FF',
                    '#0EB17A',
                    '#c8ba23',
                    '#BC6BF7',
                    '#0EB17A',
                    '#FE6463',
                    '#09246B',
                    '#7CB8FF',
                  ];
                  return colorList[params.dataIndex];
                },
              },
              color: '#3971ff',
              barBorderRadius: [0, 4, 4, 0],
            },
          },
        ],
      };
      chartColumn.setOption(option);
      chartColumn.on('click', (param) => {
        console.log(444444444);
        this.dialogVisible = true;
        this.dialogType = 4;
        this.dialogParam.title = this.nowDate + param.name + '统计';
        this.$refs['dialog'].getIllNums(param.name);
      });
    },
    initJyzptb() {
      let chartColumn = echarts.init(this.$refs.jyzptb);
      let option = {
        tooltip: {
          trigger: 'axis',
          textStyle: {
            fontSize: '24',
          },
          axisPointer: {
            type: 'none',
          },
          formatter: function (params) {
            return params[0].name + ': ' + params[0].value;
          },
        },
        grid: {
          left: '12%',
          top: '17%',
          right: '6%',
          bottom: '19%',
        },
        xAxis: {
          data: this.rcgb.map((item) => {
            return item.illnessName;
          }),
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontSize: '2rem',
            color: '#09246B',
            interval: 0,
            formatter: function (value) {
              var texts = value;
              if (texts.length > 2) {
                // 限制长度自设
                texts =
                  texts.substr(0, 2) +
                  '\n' +
                  texts.substr(2, 2) +
                  '\n' +
                  texts.substr(4, 2);
              }
              return texts;
            },
          },
        },
        yAxis: {
          axisLabel: {
            textStyle: {
              fontSize: '3rem',
              color: '#09246B',
            },
          },
          axisLine: {
            show: true,
          },
        },
        color: ['#e54035'],
        series: [
          {
            name: '普通病排名',
            type: 'pictorialBar',
            barWidth: '80%',
            barCategoryGap: '-60%',
            // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
            symbol:
              'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
            itemStyle: {
              normal: {
                //渐变色
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#3870FF',
                  },
                  {
                    offset: 1,
                    color: '#bfd0ff',
                  },
                ]),
              },
            },
            emphasis: {
              itemStyle: {
                opacity: 1,
              },
            },
            data: this.rcgb.map((item) => {
              return item.illnessNumber;
            }),
            z: 10,
          },
          {
            name: '普通病排名',
            type: 'pictorialBar',
            barGap: '-100%',
            symbolPosition: 'end',
            symbolSize: 50,
          },
        ],
      };
      chartColumn.setOption(option);
      chartColumn.on('click', (param) => {
        this.dialogVisible = true;
        this.dialogType = 5;
        this.dialogParam.title = this.nowDate + param.name + '统计';
        this.$refs['dialog'].getSymNums(param.name);
      });
    },
    //基本情况
    initJbqk() {
      let chartColumn = echarts.init(this.$refs.jbqk);
      let option = {
        tooltip: {
          trigger: 'item',
          confine: true,
          textStyle: {
            fontSize: '16',
          },
          axisPointer: {
            type: 'none',
          },
        },
        legend: {
          itemHeight: 15,
          itemWidth: 15,
          itemGap: 16,
          bottom: '3%',
          left: '10%',
          orient: 'vertical',
          textStyle: {
            fontSize: '1.8rem',
            color: '#09246B',
          },
        },
        color: ['#5696ff', '#10b27b', '#fe9a1e', '#FAD237'], // 自定义颜色范围
        series: [
          {
            name: '学生数',
            type: 'pie',
            center: ['54%', '26%'],
            radius: ['45%', '80%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '2.4rem',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: this.baseInfo.filter((item) => item.period == '小学')[0]
                  .studentNumber,
                name: '小学学生数',
              },
              {
                value: this.baseInfo.filter((item) => item.period == '初中')[0]
                  .studentNumber,
                name: '初中学生数',
              },
              {
                value: this.baseInfo.filter((item) => item.period == '高中')[0]
                  .studentNumber,
                name: '高中学生数',
              },
              {
                value: this.baseInfo.filter(
                  (item) => item.period == '幼儿园'
                )[0].studentNumber,
                name: '幼儿园学生数',
              },
              {
                value: this.baseInfo.filter(
                  (item) => item.period == '特教学校'
                )[0].studentNumber,
                name: '特教学校学生数',
              },
            ],
          },
        ],
      };
      chartColumn.setOption(option);
    },
    //日晨午检
    initRcwj() {
      let chartColumn = echarts.init(this.$refs.rcwj);
      let option = {
        tooltip: {
          trigger: 'item',
          confine: true,
          textStyle: {
            fontSize: '20',
          },
          axisPointer: {
            type: 'none',
          },
        },
        legend: {
          itemHeight: 15,
          itemWidth: 15,
          itemGap: 16,
          bottom: '4%',
          left: '18%',
          orient: 'vertical',
          textStyle: {
            fontSize: '2rem',
            color: '#09246B',
          },
        },
        color: ['#5696FF', '#10B27B', '#FE9A1E'],
        series: [
          {
            name: '日晨午检',
            type: 'pie',
            minAngle: 300,
            roseType: 'area',
            center: ['50%', '26%'],
            radius: ['15%', '80%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '2.4rem',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              borderRadius: 8,
            },
            data: [
              {
                value: this.checkNums
                  .map((item) => item.chenNums)
                  .reduce((total, num) => {
                    return total + num;
                  }),
                name: '晨检总人数',
              },
              {
                value: this.checkNums
                  .map((item) => item.wuNums)
                  .reduce((total, num) => {
                    return total + num;
                  }),
                name: '午检总人数',
              },
              {
                value: this.checkNums
                  .map((item) => item.wanNums)
                  .reduce((total, num) => {
                    return total + num;
                  }),
                name: '晚检总人数',
              },
            ],
          },
        ],
      };
      chartColumn.setOption(option);
    },
    //日请假
    initRqj() {
      let chartColumn = echarts.init(this.$refs.rqj);
      let option = {
        tooltip: {
          trigger: 'item',
          confine: true,
          textStyle: {
            fontSize: '24',
          },
          axisPointer: {
            type: 'none',
          },
        },
        legend: {
          itemHeight: 15,
          itemWidth: 15,
          itemGap: 18,
          bottom: '6%',
          left: '23%',
          orient: 'vertical',
          textStyle: {
            fontSize: '2rem',
          },
        },
        graphic: [
          {
            //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: '33%',
            top: '18%',
            style: {
              text:
                this.leaveNums
                  .map((item) => item.sNums)
                  .reduce((total, num) => {
                    return total + num;
                  }) +
                this.leaveNums
                  .map((item) => item.bNums)
                  .reduce((total, num) => {
                    return total + num;
                  }),
              textAlign: 'center',
              fill: '#09246B', //文字的颜色
              fontSize: '3rem',
              fontFamily: 'YouSheBiaoTiHei',
            },
          },
          // 写了两个文字插入是因为业务要求两行不一样样式的字
          {
            //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: '26%',
            top: '28%',
            style: {
              text: '请假总人数',
              textAlign: 'center',
              fill: '#1C4AC2', //文字的颜色
              fontSize: '2.2rem',
              fontFamily: 'PingFangSC', // 这个自己看着办
            },
          },
        ],
        color: ['#5696FF', '#10B27B'],
        series: [
          {
            name: '日请假',
            type: 'pie',
            center: ['55%', '26%'],
            radius: ['70%', '85%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '2.4rem',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: this.leaveNums
                  .map((item) => item.sNums)
                  .reduce((total, num) => {
                    return total + num;
                  }),
                name: '事假人数',
              },
              {
                value: this.leaveNums
                  .map((item) => item.bNums)
                  .reduce((total, num) => {
                    return total + num;
                  }),
                name: '病假人数',
              },
            ],
          },
        ],
      };
      chartColumn.setOption(option);
    },
    initRzzpm() {
      let chartColumn = echarts.init(this.$refs.rzzpm);
      const COLOR_ARRAY = {
        chart_3: {
          color_1: ['#0EB17A', '#3870FF', '#FE9A1E'],
        },
      };
      let dataList3 = {
        list: this.rzz.map((item) => {
          return { name: item.illnessName, value: item.illnessNumber };
        }),
      };
      let maxValue = dataList3.list[0].value;
      const grayBar = dataList3.list.map((item) => {
        return item.value * 1;
      });
      const xList = dataList3.list.map((item) => {
        return item.name;
      });
      const vStyleList = dataList3.list.map((item, index) => {
        let i = index;
        if (index > 1) {
          i = 2;
        }
        let color =
          item.value < 10
            ? COLOR_ARRAY.chart_3.color_1[0]
            : item.value < 100
            ? COLOR_ARRAY.chart_3.color_1[1]
            : COLOR_ARRAY.chart_3.color_1[2];
        let itemStyle = {
          color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            {
              offset: 1,
              color: color,
            },
          ]),
        };
        const data = {
          value: item.value,
          itemStyle,
        };
        return data;
      });
      const vList = dataList3.list.map((item) => {
        return item.value;
      });
      let option = {
        grid: {
          left: '32%',
          right: '18%',
          bottom: '2%',
          top: '2%',
        },
        xAxis: [
          {
            show: false,
          },
        ],
        yAxis: {
          type: 'category',
          inverse: true,
          axisLabel: {
            show: false, //让Y轴数据不显示
          },
          axisTick: {
            show: false, //隐藏Y轴刻度
          },
          axisLine: {
            show: false, //隐藏Y轴线段
          },
        },
        series: [
          //背景色
          {
            show: true,
            type: 'bar',
            barWidth: '40%',
            itemStyle: {
              color: 'rgba(13, 118, 93, 0)', //填充色
            },
            label: {
              show: true,
              color: '#09246B',
              fontSize: '3rem',
              position: 'right',
              formatter: function (data) {
                return '\t' + vList[data.dataIndex] + '人';
              },
            },
            z: 1,
            data: grayBar,
          },
          //蓝条
          {
            show: true,
            type: 'bar',
            barGap: '-110%',
            barWidth: '40%',
            z: -12,
            max: 1,
            label: {
              show: true,
              // textStyle: {
              color: '#09246B', //百分比颜色
              fontSize: '3rem',
              // },
              position: 'left',
              formatter: function (data) {
                //富文本固定格式{colorName|这里填你想要写的内容}
                return xList[data.dataIndex] + '\t';
              },
            },
            data: vStyleList,
          },
          {
            name: 'dotted',
            type: 'pictorialBar',
            symbol: 'rect',
            itemStyle: {
              color: '#fff',
            },
            symbolRepeat: true,
            symbolSize: [2, 50],
            symbolMargin: 5,
            data: vList,
          },
        ],
      };
      chartColumn.setOption(option);
      chartColumn.on('click', (param) => {
        this.dialogVisible = true;
        this.dialogType = 6;
        this.dialogParam.title =
          this.nowDate + dataList3.list[param.name].name + '症状统计';
        this.$refs['dialog'].getSymptom(dataList3.list[param.name].name);
      });
    },
    //点击基础信息
    baseInfoClick() {
      this.dialogVisible = true;
      this.dialogType = 1;
      this.dialogParam.width = '110rem';
      this.dialogParam.title = '基本情况';
      this.$refs['dialog'].getBase();
    },
    //当日晨午检
    chenwuClick() {
      this.dialogVisible = true;
      this.dialogType = 2;
      this.dialogParam.title = this.nowDate + '晨午检';
      this.dialogParam.width = '110rem';
      this.$refs['dialog'].getcheckNums();
    },
    //请假
    leaveNumsClick() {
      this.dialogVisible = true;
      this.dialogType = 3;
      this.dialogParam.title = this.nowDate + '请假';
      this.dialogParam.width = '140rem';
      this.$refs['dialog'].getleaveNums();
    },
  },
};
</script>
<style>
html {
  font-size: 10px;
}

/* @media screen and (width: 5760px) {
    html{
      font-size: 5px;
    }
  }
  @media screen and (width: 8640px) {
    
  } */
</style>
<style scoped lang="scss">
@font-face {
  font-family: Rajdhani;
  src: url('../../assets/fonts/站酷仓耳渔阳体-W05.ttf');
}

@font-face {
  font-family: DS-Digital;
  src: url('../../assets/fonts/DS-DIGIB-2.ttf');
}

@font-face {
  font-family: PingFangSC;
  src: url('../../assets/fonts/苹方黑体-中粗-简.ttf');
}

@font-face {
  font-family: YouSheBiaoTiHei;
  src: url('../../assets/fonts/YouSheBiaoTiHei-2.ttf');
}

@mixin scrollBarStyle() {
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2rem;
    -webkit-box-shadow: inset 0 0 0.5rem #3870ff;
    background: #3870ff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2rem;
    -webkit-box-shadow: inset 0 0 0.5rem #3870ff;
    background: #3870ff;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.5rem rgba(210, 222, 255, 0.6);
    border-radius: 0;
    background: rgba(210, 222, 255, 0.6);
  }
}

.table {
  ::v-deep colgroup {
    width: 0 !important;
  }

  ::v-deep.el-table {
    color: #1c4ac2;
    font-size: 2.8rem;
    font-family: PingFangSC !important;
    display: flex;
    flex-direction: column;
  }

  ::v-deep thead {
    .cell {
      text-align: center;
      line-height: 3rem;
    }
  }

  ::v-deep tbody {
    background: #fff;
    border: 1px #000 solid;
    flex: 1;

    .cell {
      text-align: center;
      line-height: 2.5rem;
    }

    .el-table__row {
      // height: 6.3rem;
      cursor: pointer;

      td:first-child {
        .cell {
          text-align: left;
          color: #09246b;
        }
      }
    }
  }

  ::v-deep.el-table thead {
    color: #09246b;
  }

  ::v-deep.el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border: none;
  }

  ::v-deep.el-table tr {
    background: transparent;
  }

  ::v-deep .el-table,
  .el-table__expanded-cell {
    background-color: transparent;
  }

  ::v-deep .el-table tr {
    background-color: transparent !important;
  }

  ::v-deep .el-table--enable-row-transition .el-table__body td,
  .el-table .cell {
    background-color: transparent;
  }

  ::v-deep.el-table::before {
    //去除底部白线
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
  }
}

.font-class-weight {
  font-weight: bold;
  color: #09246b;
}

.box {
  // border: 1px #000 solid;
  // width: 1920px;
  // height: 360px;
  .container {
    //width: 5760px;
    //height: 1080px;
    //height: 100vh;
    // width: 8640px;
    // height: 1620px;
    //   width: 100%;
    // height: 100vh;
    // width: 100vw;
    // min-width: 570rem;
    // width: 100vw;
    // width: 100vw;
    //8640*1620
    display: flex;
    flex-direction: column;

    .header {
      height: 9.4rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .now-time {
        font-family: DS-Digital;
        font-size: 5.6rem;
      }

      .time,
      .weather {
        color: #fff;
        font-family: PingFangSC;
        font-size: 4rem;
        width: 80rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 7.5rem;
        }
      }

      .title {
        font-family: Rajdhani;
        font-size: 5.5rem;
        display: flex;
        align-items: center;
        line-height: 100%;
        text-align: center;
        color: #fff;
        letter-spacing: 0.3rem;

        img {
          height: 7.6rem;
          margin-right: 4rem;
        }
      }

      background: url('../../assets/statisitics/Group 1460@2x.png');
      background-size: 100% 100%;
    }

    .content {
      width: 100%;
      flex: 1;
      background-image: url('../../assets/image/shading.png');
      background-size: 100% 100%;
      // background: url('../../assets/statisitics/Group 1459@2x.png');
      // background-size: 100% 100%;
      display: flex;
      flex-direction: row;
      padding: 1.5rem;
      position: relative;

      .wcj {
        height: 100%;
        flex: 2 !important;

        .item-box {
          background: transparent !important;
          backdrop-filter: unset !important;
        }

        // width: 100%;
        // overflow: hidden;
      }

      .l-b {
        // border: 1px #000 solid;
        position: absolute;
        left: 0;
        width: 37.3%;
        bottom: 0;
        height: 50%;
        display: flex;
        padding: 1.5rem 0 3rem 3rem;
        justify-content: space-between;

        .item-box {
          border-radius: 2rem;
          // border: 0.2rem solid #000;
          box-shadow: 0px 0px 20px 0px rgba(41, 91, 173, 0.1);
          backdrop-filter: blur(1rem);
          overflow: hidden;
          background: #ffffff;
          width: 49.3%;

          .echarts {
            height: calc(100% - 7rem);
          }
        }
      }

      .col-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 0px;

        .item-box {
          border-radius: 2rem;
          // border: 0.2rem solid #000;
          box-shadow: 0px 0px 20px 0px rgba(41, 91, 173, 0.1);
          backdrop-filter: blur(1rem);
          // padding: 0.5rem;
          // height: 100%;
          overflow: hidden;
          margin: 1.5rem;
          background: #ffffff;
          // background: linear-gradient(180deg, #f1f5ff, #ffffff 100%);
          flex: 1;
          height: 0;

          // margin: 2rem;
          .center {
            display: flex;
            flex-direction: column;
            height: 100%;

            .top {
              .totals {
                position: relative;

                .total {
                  text-align: center;

                  .icon {
                    margin: 0 auto;
                  }

                  .title {
                    color: #09246b;
                    font-size: 2.2rem;
                    font-family: PingFangSC;
                    margin-top: 1rem;
                  }

                  .num {
                    color: #09246b;
                    font-size: 4.8rem;
                    font-family: YouSheBiaoTiHei;
                  }

                  height: 10rem;
                  position: absolute;
                  width: 100%;
                }
              }

              height: 34%;
              display: flex;
              justify-content: space-between;

              > div {
                width: 19%;
                padding-bottom: 2rem;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 1rem;
                  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                  object-fit: cover;
                }
              }
            }

            .bottom {
              background: #fff;
              border-radius: 2rem;
              // border: 1px #000 solid;
              flex: 1;

              // padding-top: 1rem;
              .echarts {
                height: calc(100% - 7rem);
                // height: 100%;
              }
            }
          }

          .chart-layout {
            height: calc(100% - 7rem);
            display: flex;
            padding: 2rem;

            > div {
              // border: 1px #000 solid;
              // padding: 0 2rem;
              // margin-top: 4rem;
            }

            .table {
              width: 70%;
            }

            .chart {
              width: 30%;

              .echarts {
                height: 100%;
                overflow: hidden;
              }
            }
          }

          .echarts {
            height: calc(100% - 7rem);
            width: 100%;
            overflow: hidden;
            @include scrollBarStyle;
            // .list {
            //   display: flex;
            //   justify-content: space-between;
            //   background: linear-gradient(270deg,
            //       rgba(124, 184, 255, 0) 0%,
            //       rgba(56, 112, 255, 0.1) 100%);
            //   padding: 0.5rem;
            //   font-family: PingFangSC;
            //   font-size: 1.5rem;
            //   color: #09246b;
            //   border-radius: 0.4rem;
            //   margin: 0.7rem;
            // }
          }
        }
      }
    }
  }
}
</style>
